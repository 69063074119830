import React from "react";
import buttonClose from "../images/button-close3.svg";
import guestsWork from "../images/green-plate.jpg";
import guestsWork1 from "../images/guestsworks1.jpg";
import guestsWork2 from "../images/guestsworks2.png";
import guestsWork3 from "../images/guestsworks3.png";
import guestsWork6 from "../images/blue-plate.jpg";
import guestsWork7 from "../images/guestsworks7.png";
import guestsWork8 from "../images/guestsworks8.png";
import guestsWork9 from "../images/guestsworks9.jpg";
import guestsWork10 from "../images/guestsworks10.jpg";
import guestsWork11 from "../images/guestsworks11.jpg";
import guestsWork12 from "../images/guestsworks12.jpg";
import guestsWork13 from "../images/hug.jpg";
import guestsWork14 from "../images/guest1.jpg";
import guestsWork15 from "../images/guest2.jpg";
import guestsWork16 from "../images/guest3.jpg";
import guestsWork17 from "../images/guest4.jpg";
import guestsWork18 from "../images/guest5.jpg";
import guestsWork19 from "../images/guest6.jpg";
import guestsWork20 from "../images/guest22.jpg";
import guestsWork21 from "../images/guest23.jpg";

const images = [
    guestsWork3,
    guestsWork,
    guestsWork1,
    guestsWork8,
    guestsWork17,
    guestsWork2,
    guestsWork19,
    guestsWork13,
    guestsWork6,
    guestsWork21,
    guestsWork16,
    guestsWork12,
    guestsWork7,
    guestsWork9,
    guestsWork18,
    guestsWork11,
    guestsWork20,
    guestsWork15,
    guestsWork10,
    guestsWork14,
]

function  Guests() {
    const [isGalleryOpen, setIsGalleryOpen] = React.useState(false)
    const [currentImage, setCurrentImage] = React.useState('')

    function openGallery(e) {
        setIsGalleryOpen(true);
        setCurrentImage(e.target.src)
    }

    function handleClosePopup() {
        setIsGalleryOpen(false)
    }
    
    return (
        <>
            <div className="guests__images">
                {images.map((image) =>
                    <div className="guests__wrap-image">
                         <img 
                            key={image}
                            src={image}
                            alt="Работа гостя"
                            className="guests__image"
                            onClick={openGallery}
                            value={image}
                        />
                    </div> 
                )}
            </div>

            {isGalleryOpen && <div className="guests__gallery">
                <div className="guests__popup">
                    <div className="guests__popup-container">
                        <button className="popup__btn-close" onClick={handleClosePopup}>
                            <img
                            className="popup__button-close-image"
                            alt="Кнопка закрытия попапа"
                            src={buttonClose}
                            />
                        </button>

                        <img src={currentImage} alt="Изображение в галлерее" className="guests__gallery-image"/>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Guests;
