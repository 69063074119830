function Teachers() {
  return (
    <div className="teachers__block">
      <div className="teacher__block">
        <div className="teacher1__image"></div>
          <h4 className="teacher__title">Марина</h4>
          <p className="teacher__text">
          Керамист и основатель студии.
          </p>
          <p className="teacher__text">
            Керамикой занимается более 5 лет.
            Сначала это было простым увлечением после работы, но быстро
            переросло в что-то большее. 
          </p>
          <p className="teacher__text">
          В керамике любит ручную лепку,
            анималистичные сюжеты, смешение разных глазурей и эксперименты.
          </p>
      </div>
      <div className="teacher__block">
        <div className="teacher2__image"></div>
          <h4 className="teacher__title">Полина</h4>
          <p className="teacher__text">
          Преподает в школе и учит детей рисовать. Помимо этого Полина
            много рисует сама, с удовольствием расписывает стены и одежду,
            занимается керамикой и эпоксидной смолой.
          </p>
          <p className="teacher__text">
          Полина проведет для вас индивидуальный или тематический групповой мастер-класс, где вы сможете сделать прекрасную творческую работу.
          </p>
      </div>
    </div>
  );
}
export default Teachers;
